import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import SEO from "../../components/seo"

import ProductListInfo from "../../components/ProductList/ProductListInfo"

const ProductListPage = ({ data }) => {
  const products = data?.shopifyCollection?.products || []

  React.useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      if (e.target.tagName === "IMG") {
        e.preventDefault()
        e.stopPropagation()
      }
    })
  })

  return (
    <>
      <SEO
        path="/shop/animals-nature-prints/"
        title="Animals &amp; Nature - Jessica Coppet"
      />
      <ProductListInfo title={"Animals & Nature"} products={products} search={false} />
    </>
  )
}

ProductListPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProductListPage

export const query = graphql`
  query AllAnimalsNatureProducts {
    shopifyCollection(title: { eq: "Animals & Nature" }) {
      products {
        id
        shopifyId
        title
        handle
        productType
        publishedAt
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        images {
          id
          originalSrc
        }
      }
    }
  }
`
